body {
    width: 100%;
    min-height: 700px;
}

a {
    cursor: pointer;
    text-decoration: underline;
}

.ui-dialog-overlay {
    background: #aaaaaa;
    opacity: .3;
    filter: Alpha(Opacity=30);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.ui-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    outline: 0 none;
    padding: 0 !important;;
    z-index: 101;
    background-color: white;
    border: 1px solid #f6f6f6;
}

.ui-dialog.maximized{
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

.ui-dialog.minimized{
    position: fixed;
    bottom: 0;
    right: 0;
}

.ui-dialog .ui-dialog-titlebar {
    position: relative;
    font-size: 1em;
    border-radius: 3px;
    padding: 0.5em;
    height: 35px;
    border-bottom: 1px solid #f6f6f6;
}

.ui-dialog.react-draggable .ui-dialog-titlebar {
    cursor: move;
}

.ui-dialog .ui-dialog-titlebar .action-items {
    float: right;
    position: relative;
}

.ui-dialog .ui-dialog-titlebar .title {
    float: left;
    margin-right: .5em;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.5em;
}

.icon {
    width: 24px;
    height: 24px;
    display: block;
    float: left;
    margin: 5px;
    cursor: pointer;
    background-size: cover;
}

.icon.icon-close {
    width: 20px;
    height: 20px;
    background-image: url("./img/close.png");
}

.icon.icon-minimize {
    background-image: url("./img/minimize.png");
}

.icon.icon-maximize {
    background-image: url("./img/maximize.png");
}

.icon.icon-restore {
    background-image: url("./img/restore.png");
}

.ui-dialog .ui-dialog-content {
    background: none repeat scroll 0 0 transparent;
    border: 0 none;
    overflow: auto;
    position: relative;
    padding: 0.5em;
}

.ui-dialog .ui-dialog-buttonpane {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: right;
    border-width: 1px 0 0 0;
    border-top: 1px solid #f6f6f6;
}

.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset{
    padding: 0.5em;
}

.ui-dialog .ui-dialog-buttonpane button {
	margin: 0 .5em 0 .5em;
    cursor: pointer;
    background-color: #f6f6f6;
    padding: 0.5em 1em;
    outline: none;
    border: 1px solid #CCCCCC;
    border-radius: 3px;
}

.ui-dialog .ui-dialog-buttonpane button:hover{
    background-color: #CCCCCC;
    border: 1px solid #BBBBBB;
}

.ui-dialog .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
}